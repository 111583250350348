<template>
    <div class="o365-calendar-grid">
        <SidePanel :id="`${dataObject.id}-sidepanel`" :tabs="sidePanelTabs" headerClass="o365-card-header" :detailIframe="detailIframe" :detailTabTitle="detailTabTitle"
            footerClass="o365-footer" :blockDetailsPointerEvents="blockDetailsPointerEvents">
            <div class="row-container">
                <ErrorBoundry v-if="containerHasHeader" type="span" uiTitleMessage="Am unhandled error has occured when trying to render this card header"
                    uiMessage="Grid Header Render Error" :consoleMessage="`Error encountered when trying to render grid cardheader: ${calendarGridControl?.id}`">
                    <div class="o365-card-header hstack pt-2">
                            <h4 v-if="headerTitle" class="mb-0 p-2 me-2">{{$t(headerTitle)}}</h4>
                            <slot name="header"></slot>
                    </div>
                </ErrorBoundry>
                <div ref="headerRef" class="o365-calendar-grid-header" v-drag-scroll @dragscrollstart="() => blockDetailsPointerEvents = true"
                    @dragscrollend="() => blockDetailsPointerEvents = false"
                    :style="{
                        'min-height': 24 * (calendarGridControl.viewModeIndex + 1) + 'px'
                    }">
                    <div class="d-flex position-relative" :style="{
                        'min-width': totalWidth + 'px'
                    }">
                        <template v-for="scrollItem in scrollData">
                            <template v-for="(step, stepIndex) in calendarGridControl.steps">
                                <div v-if="calendarGridControl.viewModeIndex >= stepIndex && (scrollItem.item.stepHeaders[step] || currentPosition === scrollItem.index)" class="o365-calendar-grid-header-item position-absolute"
                                    :style="{
                                        'left': scrollItem.pos - scrollItem.item.leftAdjustments[step] + 'px',
                                        'top': 24 * stepIndex + 'px',
                                        'width': scrollItem.item.widths[step] + 'px',
                                        'min-width': scrollItem.item.widths[step] + 'px',
                                        'max-width': scrollItem.item.widths[step] + 'px'
                                    }"
                                    >
                                    {{
                                        stepIndex === 2 && calendarGridControl.viewModeIndex == 2
                                            ? `${scrollItem.item.display.week} - ${scrollItem.item.display.day}`
                                            : scrollItem.item.display[step]
                                    }}
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
                <div ref="bodyRef" class="o365-calendar-grid-body bg-light" @scroll.passive="handleScroll" v-drag-scroll:nochilddrag
                    @dragscrollstart="() => blockDetailsPointerEvents = true" @dragscrollend="() => blockDetailsPointerEvents = false">
                    <div class="d-flex h-100 position-relative"
                        :style="{
                            'min-width': totalWidth + 'px'
                        }">
                        <CalendarColumn v-for="scrollItem in scrollData" :key="scrollItem.index" :scrollItem="scrollItem" :cardWidth="cardWidth"
                            :cardHeight="cardHeight" :calendarGridControl="calendarGridControl" :cardHeightPadding="cardHeightPadding">
                            <template v-if="$slots.card" #card="{row}">
                                  <slot name="card" :row="row"></slot>
                            </template>
                            <template v-if="$slots.cardContent" #cardContent="{row}">
                                  <slot name="cardContent" :row="row"></slot>
                            </template>
                        </CalendarColumn>
                    </div>
                </div>
                <div v-if="!noFooter" class="o365-footer py-1 p-1 d-flex align-items-center">
                    <div class="d-flex mx-1">
                        <ODatePicker v-model="startDate" format="Short Date" :clearable="false" containerClass="mx-1" :maxDate="endDate"
                            preventMinMaxNavigation :callback="() => calendarGridControl.load()">
                            <template #default="{value}">
                                <button :title="$t('Start date')" class="btn btn-sm btn-link text-decoration-none p-0">{{value}}</button>
                            </template>
                        </ODatePicker>
                         <span class="text-primary"> - </span>
                        <ODatePicker v-model="endDate" format="Short Date" :clearable="false" containerClass="mx-1" :minDate="startDate"
                            preventMinMaxNavigation :callback="() => calendarGridControl.load()">
                            <template #default="{value}">
                                <button :title="$t('End date')" class="btn btn-sm btn-link text-decoration-none p-0">{{value}}</button>
                            </template>
                        </ODatePicker>
                    </div>
                    <ODropdown>
                        <template #default="{target, open}">
                            <button :ref="target" class="btn btn-link btn-sm" @click="open"
                                :title="$t('View mode')">
                                <i v-if="calendarGridControl.viewMode === 'day'" class="bi bi-calendar-day"></i>
                                <i v-else-if="calendarGridControl.viewMode === 'week'" class="bi bi-calendar-week"></i>
                                <i v-else-if="calendarGridControl.viewMode === 'month'" class="bi bi-calendar-month"></i>
                                <i v-else-if="calendarGridControl.viewMode === 'year'" class="bi bi-calendar"></i>
                            </button>
                        </template>
                        <template #dropdown="{container, close}">
                            <div :ref="container" class="dropdown-menu show">
                                <button class="dropdown-item" :title="$t('Years')" @click="() => { calendarGridControl.setViewMode('year'); close(); }"
                                    :class="{'active': calendarGridControl.viewMode === 'year'}">
                                    <i class="bi bi-calendar me-1"></i>
                                    {{$t('Years')}}
                                </button>
                                <button class="dropdown-item" :title="$t('Months')" @click="() => { calendarGridControl.setViewMode('month'); close(); }"
                                    :class="{'active': calendarGridControl.viewMode === 'month'}">
                                    <i class="bi bi-calendar-month me-1"></i>
                                    {{$t('Months')}}
                                </button>
                                <button class="dropdown-item" :title="$t('Weeks')" @click="() => { calendarGridControl.setViewMode('week'); close(); }"
                                    :class="{'active': calendarGridControl.viewMode === 'week'}">
                                    <i class="bi bi-calendar-week me-1"></i>
                                    {{$t('Weeks')}}
                                </button>
                                <button class="dropdown-item" :title="$t('Days')" @click="() => { calendarGridControl.setViewMode('day'); close(); }"
                                    :class="{'active': calendarGridControl.viewMode === 'day'}">
                                    <i class="bi bi-calendar-day me-1"></i>
                                    {{$t('Days')}}
                                </button>
                            </div>
                        </template>
                    </ODropdown>
                    <button class="btn btn-link btn-sm" @click="calendarGridControl.load"><i class="bi bi-arrow-clockwise"></i></button>
                    <OFilterListDropdown :dataObject="dataObject" />
                    <OFilterString  :filterObject="dataObject.filterObject" class="text-truncate mt-1" style="display: flex; align-items: baseline;">
                        <template #clear="{clear}">
                            <button class="btn btn-sm btn-link px-1 pt-0 ms-2 text-danger" @click="clear()" :title="$t('Clear current filter')">
                                <i class="bi bi-x-circle-fill"></i>
                            </button>
                        </template>
                    </OFilterString>
                    <OSaveFilter :dataObject="dataObject" className="d-flex" 
                        saveClass="btn btn-link btn-sm ms-auto"
                        saveAsClass="btn btn-link btn-sm ms-1 text-nowrap"/>
                    <button v-if="dataObject?.filterObject?.persistentFilterId" class="btn btn-sm btn-link py-0" @click="togglePersistentFilter()" 
                        :title="$t('Set filter as default')"
                        :style="dataObject.filterObject.persistentFilterEnabled ? 'color: var(--o365-active-filter)!important;' : ''">
                        <i v-if="dataObject.filterObject.persistentFilterEnabled" class="bi bi-person-check-fill"></i>
                        <i v-else class="bi bi-person-check-fill"></i>
                    </button>
                </div>
            </div>

            <template #tab-content(filters)>
                <ActiveFilters :dataObject="dataObject" hideHeader />
            </template>
        </SidePanel>
    </div>
</template>

<script setup lang="ts">
import type DataObject from 'o365.modules.DataObject.ts';

import CalendarGridControl from 'o365.controls.CalendarGrid.ts';
import { calendarGridControlKey } from 'o365.modules.vue.injectionKeys.js';
import useVirtualScroll from 'o365.vue.composables.VirtualScroll.ts';
import ErrorBoundry from 'o365.vue.components.ErrorBoundry.vue';
import SidePanel from 'o365.vue.components.SidePanel.vue';
import $t from 'o365.modules.translate.ts';
import vDragScroll from 'o365.lib.vue-dragscroll.ts';
import useAsyncComponent from 'o365.vue.composables.AsyncComponent.ts';
import CalendarColumn from 'o365.vue.components.CalendarGrid.Column.vue';
import ODropdown from 'o365.vue.components.DropDown.vue';
import ODatePicker from 'o365.vue.components.DatePicker.vue';

import { onMounted, onUnmounted, provide, ref, computed, useSlots } from 'vue';

const ActiveFilters = useAsyncComponent('o365.vue.components.ActiveFilters.vue');
const OFilterListDropdown = useAsyncComponent('o365.vue.components.FilterListDropdown.vue');
const OSaveFilter = useAsyncComponent('o365.vue.components.SaveFilter.vue');
const OFilterString = useAsyncComponent('o365.vue.components.FilterString.vue');

const props = withDefaults(defineProps<{
    dataObject: DataObject,
    bindingField: string,
    cardWidth?: number | string,
    cardHeight?: number | string,
    cardHeightPadding?: number | string,
    headerTitle?: string,
    noFooter?: boolean,
    fieldFilters?: string[]
    detailIframe?: string,
    disableCurrentIndexSetOnLoad?: boolean,
    detailTabTitle?: string,
    disableVerticalVirtualScroll?: boolean,
}>(), {
    cardWidth: 300,
    cardHeight: 185,
    cardHeightPadding: 10,
});
const slots = useSlots();

const startDate = defineModel<Date>('startDate');
const endDate = defineModel<Date>('endDate');
const viewMode = defineModel<string>('viewMode', { default: 'week'});

if (startDate.value == null) {
    const defaultStartDate = new Date();
    defaultStartDate.setDate(defaultStartDate.getDate() - 30);
    startDate.value = defaultStartDate; 
}
if (endDate.value == null) {
    const defaultEndDate = new Date();
    defaultEndDate.setDate(defaultEndDate.getDate() + 270);
    endDate.value = defaultEndDate; 
}


const models = {
    startDate, endDate, viewMode
}

const calendarGridControl = ref(new CalendarGridControl(props, models));
calendarGridControl.value.initialize();
provide(calendarGridControlKey, calendarGridControl);

const columns = computed(() => calendarGridControl.value.columns);
const watchTarget = computed(() => calendarGridControl.value.updated);
const headerRef = ref<HTMLElement>(null);
const bodyRef = ref<HTMLElement>(null);
const blockDetailsPointerEvents = ref(false);


const sidePanelTabs = [
    { id: 'filters', title: $t('Filters'), iconClass: 'bi bi-funnel'},
];

const totalWidth = computed(() => {
    return calendarGridControl.value.columns.length * +props.cardWidth
});

const containerHasHeader = computed(() => {
    return (slots.header !== undefined && slots.header().length !== 0) || !!props.headerTitle;
});

// const { scrollData, handleScroll, updateData: updateVirtualScrollData, totalHeight: totalWidth,
const { scrollData, handleScroll, updateData: updateVirtualScrollData, currentPosition
} = useVirtualScroll({
    dataRef: columns,
    itemSize: props.cardWidth,
    elementRef: bodyRef,
    watchTarget: () => watchTarget.value,
    defaultRowHeight: props.cardWidth,
    horizontal: true,
    // getRowHeight: row => row.details > 0 ? 300 : 300
});

function togglePersistentFilter() {
    if (props.dataObject.filterObject.appliedFilterString) {
        props.dataObject.filterObject.setPersistentFilter();
        props.dataObject.filterObject.persistentFilterEnabled = true;
    } else {
        props.dataObject.filterObject.removePersistentFilter();
        props.dataObject.filterObject.persistentFilterEnabled = false;
    }
}

onMounted(() => {
    calendarGridControl.value.initializeContainer({
        body: bodyRef.value,
        header: headerRef.value
    });
});

onUnmounted(() => {
    calendarGridControl.value.destroy();
});

</script>

<style scoped>
.o365-calendar-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: unset;

    border:1px solid var(--o365-grid-border)!important;
    overflow: hidden;
    position: relative;
    border-radius: var(--bs-border-radius);
    outline: none;
}

.o365-calendar-grid :deep(.o365-card-header) {
   background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
    white-space:  nowrap;
    height: 50px;
    max-height: 50px;
}

.o365-calendar-grid .o365-calendar-grid-body {
    min-height: unset;
    overflow: auto;
    flex: 1;
    cursor: grab;
}
.o365-calendar-grid .o365-calendar-grid-header {
    overflow: hidden;
    cursor: grab;
}

.o365-calendar-grid-header-item {
    height: 24px;
    background-color: rgba(var(--bs-primary-rgb), .5);
    /* border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; */
    border-right: 1px solid rgba(var(--bs-primary-rgb), .7);
    border-bottom: 1px solid rgba(var(--bs-primary-rgb), .7);
    /* border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; */
    text-align: center;
    align-content: center;
    font-weight: 600;
}
.o365-calendar-grid-column {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    /* border-right: 1px solid gray; */
    /* border-bottom: 1px solid gray; */
    /* text-align: center; */
    /* align-content: center; */
}

.o365-calendar-grid :deep(.o365-footer){
    border-top: 1px solid var(--o365-grid-cell-border);
    margin-top: 0px;
    height: 40px;
    min-height: 40px;
    text-overflow: ellipsis;
    background: var(--o365-grid-footer);
    z-index:2;
}

.o365-calendar-grid :deep(.o365-calendar-active-drop-target) {
    outline: 4px solid rgba(var(--bs-primary-rgb), 1)!important;
    outline-offset: -4px;
}

</style>